






























































import Base from '@/mixins/Base.vue';
import { IFlow, IFlowResponse } from '@/interfaces/flow';

const component = Base.extend({
    data() {
        return {

            active: false,
            flow: {} as IFlow,
        };
    },
    mounted() {
        this.active = true;
    },
    methods: {
        save(): void {
            this.post<IFlowResponse>('flows', {
                ...this.flow,
            }).then(({ data }) => {
                this.$router.push({ name: 'flows.edit', params: { id: data.data.id.toString() } });
            });
        },
    },
});

export default component;
